/**
 * State of the search panel
 */

import { LocaleObject } from '@nuxtjs/i18n/dist/runtime/composables'
const searchPanelVisible = ref(false)

// current selection with labels & values
const selection: Ref<{
  radio: CheckListItem | null
  type: CheckListItem | null
  keywords: string | null
  category: number | null
}> = ref({
  radio: null,
  type: null,
  keywords: null,
  category: null
})

// used for display
const selectionPrettyString = computed(() => {
  return [
    selection.value.radio?.label,
    selection.value.type?.label,
    selection.value.keywords,
    selection.value.category?.label
  ]
    .filter((i) => i)
    .join(', ')
})

const radios: Ref<Array<Radio>> = ref([])
const typeItems: Ref<{ value: string; label: string }> = ref([])
const radiosItems = ref([])
const categoriesItems = ref([])

const hasChosenRadio = ref(false)

export const useNavbarSearchPanel = () => {
  const { t } = useI18n()
  const { fetchData: fetchCategories, categoriesItems } = useProgramCategories()
  const route = useRoute()

  const clearSelection = () => {
    selection.value.radio = null
    selection.value.type = null
    selection.value.keywords = null
  }

  const fetchData = async () => {
    /**
     * fetch radios
     */
    const response = await useApiFetch<Radio>('/radio', {
      fields: 'slug,name,translations.*',
      filter: {
        group: {
          group_id: {
            _eq: 1
          }
        }
      }
    })

    radios.value = response.data.value?.data || []
    radiosItems.value = radios.value.map((r: Radio) => {
      return { label: r.name, value: r.slug }
    })
    await fetchCategories()
    categoriesItems.value = categoriesItems.value

    /**
     * types data
     */
    typeItems.value = [
      {
        label: t('NavbarSearchPanel.types.episode'),
        value: 'episode'
      },
      {
        label: t('NavbarSearchPanel.types.program'),
        value: 'program'
      },
      {
        label: t('NavbarSearchPanel.types.news'),
        value: 'news'
      },
      {
        label: t('NavbarSearchPanel.types.event'),
        value: 'event'
      }
    ]
  }

  const setSelectionFromQuery = () => {
    const route = useRoute()

    // radio checklist
    // if (route.query['radio']) {
    //   // condition because we do not want to erase the default value set in NavbarSearchPanel
    //   selection.value['radio'] = radiosItems.value.find(
    //     (i) => i.value === route.query['radio']
    //   )
    // }

    // type checklist
    selection.value['type'] = typeItems.value.find(
      (i) => i.value === route.query['type']
    )

    // keyword input
    selection.value['keywords'] = route.query.keywords

    // optional
    selection.value['category'] = categoriesItems.value?.find(
      (i) => i.value === Number(route.query['category'])
    )
  }

  const initialize = () => {
    setSelectionFromQuery()
  }

  const getFilter = (
    type: 'episode' | 'program' | 'news' | 'event',
    localeProperties: globalThis.ComputedRef<LocaleObject>
  ) => {
    const radio = route.query.radio ? ref(route.query.radio) : ref(null)
    const keywords = ref(route.query.keywords)

    const radioOperator = ref('_eq')

    switch (type) {
      case 'episode':
        return JSON.stringify({
          translations: {
            languages_code: {
              _eq: localeProperties.value.iso
            }
          },
          // filter on program
          /* ...(program && {
          program: {
              id: {
                  _eq: program
              }
          }
          }), */
          // filter on radio
          ...(radio.value && {
            program: {
              radio: {
                slug: {
                  _in: radios.value.map(r => r.slug)
                }
              }
            }
          }),
          // filter on keywords
          ...useTranslationSearchPayload(keywords.value as string, [
            'title',
            'description'
          ])
        })
      case 'program':
        return JSON.stringify({
          translations: {
            languages_code: {
              _eq: localeProperties.value.iso
            }
          },
          // filter on radio
          ...(radio.value && {
            radio: {
              slug: {
                _in: radios.value.map(r => r.slug)
              }
            }
          }),
          /* // filter on category
        ...(props.category && {
          ...{
            categories_boa: {
              category_boa_id: {
                _in: [props.category]
              }
            }
          }
        }), */
          // filter on keywords
          ...useTranslationSearchPayload(keywords.value as string, [
            'title',
            'description',
            'introduction'
          ])
        })
      case 'news':
        return JSON.stringify({
          translations: {
            languages_code: {
              _eq: localeProperties.value.iso
            }
          },
          // filter on radio
          // ...(radio.value && {
          //   radio: {
          //     slug: {
          //       [radioOperator.value]: radio.value
          //     }
          //   }
          // }),
          // filter on keywords
          ...useTranslationSearchPayload(keywords.value as string, [
            'title',
            'content'
          ])
        })
      case 'event':
        return JSON.stringify({
          translations: {
            languages_code: {
              _eq: localeProperties.value.iso
            }
          },
          // filter on radio
          ...(radio.value && {
            radio: {
              slug: {
                _eq: radio.value
              }
            }
          }),
          // filter on keywords
          ...useTranslationSearchPayload(keywords.value as string, [
            'title',
            'content'
          ])
        })
      default:
        return {}
    }
  }

  return {
    searchPanelVisible,
    selection,
    selectionPrettyString,
    radiosItems,
    typeItems,
    hasChosenRadio,
    fetchData,
    initialize,
    clearSelection,
    getFilter
  }
}
